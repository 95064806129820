/*
const getMeaningOnTdk = (args, onClick = null) => {
    if (args === null) {
        setIsLoading(false);
        return null;
    }
    const newData = args[0];
    const word = args[0];

    let isMeaningful = false;
    let sozlukName = "";
    let itemsProcessed = 0;
    tdkSozlukPaths.forEach((path, i) => {
        fetch(tdkUrl + path + '?ara=' + word)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    isMeaningful = true;
                    sozlukName = path;
                } else {

                }
            })
            .then(x => {
                itemsProcessed++;
                if (itemsProcessed === tdkSozlukPaths.length) {
                    console.log(`${isMeaningful} and from ${sozlukName}`);
                    console.log(data);
                    if (isMeaningful) {
                        newData[1].definition = `TDK tarafından önceden tanımlanmış olması muhtemel bir kelime ${sozlukName}`;
                    }
                    _setData(newData);
                    onClick?.call();
                }
            });
    })
}*/
/*
const onButtonClick = useCallback(() => {
    if (ref.current === null) {
        return;
    }
    //ref.current.style.border = "12px solid #ffeda1";
    //ref.current.style.border = "4px solid #ffd03c";
    ref.current.style.border = "8px solid #92ace3";
    if (signRef.current !== null) signRef.current.style.visibility = "hidden";
    if (menuRef.current !== null) menuRef.current.style.display = "none";
    if (sharingFooterRef.current !== null) sharingFooterRef.current.style.display = "flex";
    toBlob(ref.current, {cacheBust: true})
        .then(async (blob) => {
            if (navigator.canShare) {
                shareImage(blob);
            } else {
                if (window.saveAs) {
                    window.saveAs(blob, 'my-node.png');
                } else {
                    console.log(blob);
                    FileSaver.saveAs(blob, 'my-node.png');
                }
            }
        }).then(x => {
        ref.current.style.border = "none";
        if (signRef.current !== null) signRef.current.style.visibility = "visible";
        if (sharingFooterRef.current !== null) sharingFooterRef.current.style.display = "none";
        if (menuRef.current !== null) menuRef.current.style.display = "flex";
    })
        .catch((err) => {
            console.log(err)
        })
}, [ref])*/
function _getLines(ctx, phrase, maxPxLength, textStyle) {
    var wa = phrase.split(" "),
        phraseArray = [],
        lastPhrase = wa[0],
        measure = 0,
        splitChar = " ";
    if (wa.length <= 1) {
        return wa
    }
    ctx.font = textStyle;
    for (var i = 1; i < wa.length; i++) {
        var w = wa[i];
        measure = ctx.measureText(lastPhrase + splitChar + w).width;
        if (measure < maxPxLength) {
            lastPhrase += (splitChar + w);
        } else {
            phraseArray.push(lastPhrase);
            lastPhrase = w;
        }
        if (i === wa.length - 1) {
            phraseArray.push(lastPhrase);
            break;
        }
    }
    if (phraseArray[0].startsWith(' ')) {
        phraseArray[0] = phraseArray[0].substring(1);
    }
    return phraseArray;
}
async function _shareImage(blob) {
    const filesArray = [new File([blob], 'bukelimeyok.png', {
        type: blob.type
    })];
    const shareData = {
        files: filesArray
    };
    await navigator.share(shareData);
}

export const getLines = _getLines;
export const shareImage = _shareImage;