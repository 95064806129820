const _tdkUrl = "https://sozluk.gov.tr/";
const _tdkSozlukPaths = ["gts", "yazim", "bati", "tarama", "derleme", "atasozu", "kilavuz", "etms", "adlar"];
const _definitions = [{
    type: 'isim',
    definition: 'Ayaklar veya bir destek üzerine oturtulmuş tabladan oluşan mobilya',
    example: '"Arkasındaki masada biri gözlüklü iki adam vardı." - Yusuf Atılgan'
}, {type: 'isim', definition: 'TDK sözlüklerinde yer almayan, yapay zeka tarafından üretilip tanımlanmış bir kelime', example: ''}

];
const _wordFromUser = "Yapay zeka tarafından tanımlanmış ve cümle içinde kullanılmış bir kelime.";
const _initialHint = "Kelimenizi yazıp Enter tuşuna basın";
const _errorHint = "Bu kelimeyi tanımlayamadık :(";
const _aboutWord = "bu kelime yok";
const _aboutSpelling = "bu — ke · li · me — yok";
const _about = [{
    type: 'isim',
    definition: 'TDK sözlükleriyle eğitilmiş, Türkçeye benzeyen kelimeler üreten, tanımlayan ve cümle içinde kullanan yapay zeka',
    example: 'Sorumluluk reddi: Tüm metinler yapay zeka tarafından üretilmiştir ve herhangi bir gözetimden geçirilmemiştir.'
}, {type: 'isim', definition: '<a href="https://twitter.com/akoksal_">@akoksal_</a> ve <a\n' +
        '            href="https://twitter.com/erkamsj">@erkamsj</a> tarafından hayata geçirilen proje.',
    example: '<a href="https://buymeacoffee.com/bukelimeyok">Kahve</a> alarak veya <a href="https://twitter.com/bukelimeyok_">Twitter</a> ve <a href="https://instagram.com/bukelimeyok">Instagram</a> üzerinden takip ederek destek olabilirsiniz.'}

];
const _disclaimer = "Tüm metinler yapay zeka tarafından üretilmiştir ve herhangi bir gözetimden geçirilmemiştir.";
const _url = "bukelimeyok.com";
const _input = {
    "data": ["", 1.1, 75, 0.95], "cleared": false, "example_id": null, "session_hash": "r3k14cnuo9n"
};

export const tdkUrl = _tdkUrl;
export const tdkSozlukPaths = _tdkSozlukPaths;
export const definitions = _definitions;
export const about = _about;
export const aboutWord = _aboutWord;
export const aboutSpelling = _aboutSpelling;
export const disclaimer = _disclaimer;
export const url = _url;
export const input = _input;
export const initialHint = _initialHint;
export const errorHint = _errorHint;
export const wordFromUser = _wordFromUser;