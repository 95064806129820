function Loading({isLoading, style}) {

    return (<div style={{visibility: isLoading ? 'visible' : 'hidden', position: 'relative', left: style !== null ? -64 : 0}}
                 className="loadingio-spinner-ellipsis-vcn6x1qbqcs">
        <div className="ldio-oa40i20wek">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>);
}

export default Loading;