import {forwardRef} from "react";

const SignLanguage = forwardRef(({word}, ref) => {
    let _word = word.replace('â', 'a');
    let unknownLetter = false;
    _word.split('').forEach((e) => {
        if (!"abcçdefgğhıijklmnoöprsştuüvyz ".includes(e))
            unknownLetter = true;
    });
    if (unknownLetter)
        return <></>;
    else
        return (<div ref={ref} className="sign-language">
            <table>
                <tbody>
                <tr>
                    {_word.split('').map((e, i) => <td key={i}>{e !== ' ' ? <img
                        src={`gifs/${e.replace("ö", "oo")
                            .replace("ü", "uu")
                            .replace("ç", "cc")
                            .replace("ş", "ss")
                            .replace("ı", "ii")
                            .replace("ğ", "gg")}.gif`}
                        className="no-resp-img"/> : <img
                        className="no-resp-img"/>}</td>)}
                </tr>
                <tr style={{textAlign: "center", color: "var(--textColor)"}}>
                    {word.split('').map((e, i) => <td key={i + '-letter'}>{e}</td>)}
                </tr>
                </tbody>
            </table>
        </div>)
});

export default SignLanguage;