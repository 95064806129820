import {input} from "../constants";
import * as PropTypes from "prop-types";
import Loading from "./loading";
function NewWord({onSubmit, isLoading, hint}) {

    function _handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit(e.target.value);
        }
    }

    return (<div className="new-word">
        <p className="small-text" style={{paddingBottom: 8}}>{hint}</p>
        <form id="word-entry-form">
            <input autoFocus={true} type="text" id="word-entry" onKeyDown={_handleKeyDown} className="word-entry" autoComplete="off"
                   autoCorrect="off"
                   autoCapitalize="off"
                   spellCheck="false" placeholder="kelime"/>
            <Loading style={{position: 'relative', left: -48}} isLoading={isLoading}/>
        </form>
    </div>);
}
NewWord.propTypes = {onSubmit: PropTypes.func};

export default NewWord;