import * as PropTypes from "prop-types";
import parse from "html-react-parser";

function Definition({def, index}) {
    return (<div className="definition-container">
        <div className="definition-bullet">
            <b className="definition-number">{index + 1}. </b>
            <p className="definition-pos" style={{color: "var(--accentColor)"}}>{def.type} </p>
        </div>
        <div className="definition-text">
            <p>
                {def.definition.includes("twitter.com/") ? parse(def.definition) : def.definition}
            </p>
            <div style={{height: 8}}/>
            <i className="example-text">{def.example.includes("buymeacoffee.com/") ? parse(def.example): def.example}</i>
        </div>
    </div>);
}

Definition.propTypes = {
    def: PropTypes.any, index: PropTypes.number
};

export default Definition;