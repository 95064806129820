import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
function AppBar({onClick}) {
    return(
        <div className="app-bar">
            <FontAwesomeIcon onClick={onClick} icon={faCircleInfo} size="m" className="app-bar-icons" />
        </div>
    );

}

export default AppBar;