import './App.css';
import {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import TypeWriterEffect from 'react-typewriter-effect';
import SignLanguage from "./components/sign-language";
import {about, aboutSpelling, aboutWord, definitions, disclaimer, errorHint, initialHint, input, url, wordFromUser} from "./constants";
import Definition from "./components/definition";
import {deleteWord, getFirebaseWord, increaseShare, log} from "./firebase";
import useKeyPress from "./utils/useKeyPress";
import {getLines, shareImage} from "./utils/utils";
import NewWord from "./components/new-word";
import Loading from "./components/loading";
import AppBar from "./components/app-bar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faShare, faStarOfLife, faRefresh } from '@fortawesome/free-solid-svg-icons';

const FileSaver = require('file-saver');

const Menu = forwardRef(({onClick}, ref) => (<div style={{overflowX:'scroll', overflowY:'hidden'}}><div ref={ref} className="menu" style={{display: 'flex', alignItems: 'center'}}>
    <a className="menu-item" onClick={() => onClick(0)}><FontAwesomeIcon className="icons with-right-margin" icon={faPen} />Kendi kelimeni gir</a>
    <p id="separator"> / </p>
    <a className="menu-item" onClick={() => onClick(1)}><FontAwesomeIcon className="icons with-right-margin" icon={faStarOfLife} />Yeni kelime</a>
    <p id="separator"> / </p>
    <a className="menu-item" onClick={() => onClick(3)}><FontAwesomeIcon className="icons with-right-margin" icon={faRefresh} />Yeniden tanımla</a>
    <p id="separator"> / </p>
    <a className="menu-item" onClick={() => onClick(2)}><FontAwesomeIcon className="icons with-right-margin" icon={faShare} />Paylaş</a>
</div></div>));

const SharingFooter = forwardRef((props, ref) => (
    <div ref={ref} className="sharing-container">
        <p className="sharing-disclaimer">{disclaimer}</p>
        <p className="sharing-site-name">{url}</p>
    </div>
));

function App() {
    const [word, setWord] = useState('');
    const [hint, setHint] = useState(initialHint);
    const [spelling, setSpelling] = useState('');
    const [size, setSize] = useState(4);
    const [isTyping, setIsTyping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, _setData] = useState(definitions);
    const [width, setWidth] = useState(window.innerWidth);
    const ref = useRef(null);
    const signRef = useRef(null);
    const menuRef = useRef(null);
    const sharingFooterRef = useRef(null);
    const canvasRef = useRef(null);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;

        const onKeyPress = async (event) => {
            if (!isTyping) {
                switch (event.key) {
                    case 'j':
                        event.preventDefault();
                        setIsTyping(true);
                        setHint(initialHint);
                        break;
                    case 'k':
                        event.preventDefault();
                        getRandomWord();
                        break;
                    case 'l':
                        onShareButtonClick();
                        break;
                    case 'r':
                        await setNewWord(word);
                        break;
                    default:
                        break;
                }
            } else {
                if (event.key === 'Escape')
                    setIsTyping(false);
            }
        };

    useKeyPress(['j', 'k', 'l', 'Escape', 'r'], onKeyPress);

    const onShareButtonClick = useCallback(async () => {
        await increaseShare(word);
        if (canvasRef.current === null) {
            return;
        }
        let c = document.getElementById("canvas");
        let ctx = c.getContext("2d");
        const padTop = 160;
        let pad1 = 28;
        const pad2 = 128;
        const pad3 = 48;
        const padNum = 16;
        const padExample = 16;
        const lineSpace = 8;
        const primaryColor = "#2660A4";

        ctx.fillStyle = "#EDF7F6";
        ctx.fillRect(0, 0, c.width, c.height);
        ctx.fillStyle = primaryColor;
        ctx.fillRect(0, c.height - 50, c.width, c.height);
        console.log(`CANVAS: ${word}`);
        /* WORD */
        ctx.font = 'bold 108px Libre Baskerville';
        ctx.fillStyle = primaryColor;
        let metrics = ctx.measureText(word);
        let h1 = metrics.actualBoundingBoxAscent
        ctx.fillText(word, 75, padTop + h1);
        h1 += metrics.actualBoundingBoxDescent;
        if (metrics.actualBoundingBoxDescent !== 0) {
            pad1 = pad1 - 16;
        }
        ctx.fillStyle = "#658ebd";
        ctx.font = '42px Libre Baskerville';
        metrics = ctx.measureText(spelling);
        let h2 = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        ctx.fillText(spelling, 75 + 4, padTop + h1 + h2 + pad1);

        /* MEANINGS */
        ctx.fillStyle = "#4b4b4b";
        ctx.font = 'bold 36px Libre Baskerville';
        let w_number = ctx.measureText("1.").width;
        ctx.fillText("1.", 75 + 4, padTop + h1 + h2 + pad1 + pad2);
        ctx.fillStyle = "#f19953";
        ctx.font = 'bold 36px Libre Baskerville';

        let w_type = ctx.measureText(data[0].type).width;
        ctx.fillText(data[0].type, 75 + 4 + w_number + padNum, padTop + h1 + h2 + pad1 + pad2);

        ctx.fillStyle = "#4b4b4b";
        ctx.font = '36px Libre Baskerville';
        let lines = getLines(ctx, data[0].definition, c.width - (75 + 75 + 4 + w_number + padNum + w_type + padNum), ctx.font);
        let lineHeight = 0;
        lines.forEach((line) => {
            metrics = ctx.measureText(line);
            ctx.fillText(line, 75 + 4 + w_number + padNum + w_type + padNum, padTop + h1 + h2 + pad1 + pad2 + lineHeight);
            let _h = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            lineHeight += _h + lineSpace;
        });
        ctx.fillStyle = "#9d9d9d";
        ctx.font = 'italic 36px Libre Baskerville';
        lines = getLines(ctx, data[0].example, c.width - (75 + 75 + 4 + w_number + padNum + w_type + padNum), ctx.font);
        lineHeight += padExample;
        lines.forEach((line) => {
            metrics = ctx.measureText(line);
            ctx.fillText(line, 75 + 4 + w_number + padNum + w_type + padNum, padTop + h1 + h2 + pad1 + pad2 + lineHeight);
            let _h = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            lineHeight += _h + lineSpace;
        });

        /* 2nd MEANING */
        ctx.fillStyle = "#4b4b4b";
        ctx.font = 'bold 36px Libre Baskerville';
        ctx.fillText("2.", 75 + 4, lineHeight + padTop + h1 + h2 + pad1 + pad2 + pad3);
        ctx.fillStyle = "#f19953";
        ctx.font = 'bold 36px Libre Baskerville';

        w_type = ctx.measureText(data[1].type).width;
        ctx.fillText(data[1].type, 75 + 4 + w_number + padNum, lineHeight + padTop + h1 + h2 + pad1 + pad2 + pad3);

        ctx.fillStyle = "#4b4b4b";
        ctx.font = '36px Libre Baskerville';
        lines = getLines(ctx, data[1].definition, c.width - (75 + 75 + 4 + w_number + padNum + w_type + padNum), ctx.font);

        lines.forEach((line) => {
            metrics = ctx.measureText(line);
            ctx.fillText(line, 75 + 4 + w_number + padNum + w_type + padNum, padTop + h1 + h2 + pad1 + pad2 + pad3 + lineHeight);
            let _h = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            lineHeight += _h + lineSpace;
        });

        /* FOOTER */
        ctx.fillStyle = "#fafafa";
        ctx.font = 'italic 16px Helvetica Neue';
        ctx.fillText(disclaimer, 16, c.height - 16 - 4);
        ctx.fillStyle = "#fff";
        ctx.font = 'bold 24px Libre Baskerville';
        ctx.fillText(url, c.width - 236 - 16, c.height - 16);
        const blob = await (await fetch(c.toDataURL())).blob();
        if (navigator.canShare && isMobile) {
            await shareImage(blob);
        } else {
            if (window.saveAs) {
                window.saveAs(blob, 'bukelimeyok.png');
            } else {
                FileSaver.saveAs(blob, 'bukelimeyok.png');
            }
        }
    }, [canvasRef, data, spelling, word])

    const getRandomWord = (onClick = null) => {
        setIsLoading(true);
        getFirebaseWord().then((doc) => {
            setIsLoading(false);
            setWord(doc.word);
            setSpelling(doc.spelling.replaceAll(' ', ' — ').replaceAll('·', ' · '));
            let newData = JSON.parse(JSON.stringify(data));
            if (doc.meaning.startsWith('a.')) {
                newData[0].definition = doc.meaning.substring(2);
                newData[0].type = "isim";
                newData[1].type = "isim";
            } else if (doc.meaning.startsWith("sf.")) {
                newData[0].definition = doc.meaning.substring(3);
                newData[0].type = "sıfat";
                newData[1].type = "sıfat";
            } else if (doc.meaning.startsWith("zf.")) {
                newData[0].definition = doc.meaning.substring(3);
                newData[0].type = "zarf";
                newData[1].type = "zarf";
            } else if (doc.meaning.startsWith("öz a.")) {
                newData[0].definition = doc.meaning.substring(5);
                newData[0].type = "isim";
                newData[1].type = "isim";
            } else {
                newData[0].definition = doc.meaning;
            }

            newData[1].definition = doc.bukelimeyok ? "TDK sözlüklerinde bulunmayan, yapay zeka tarafından üretilip tanımlanmış bir kelime" : "Yapay zeka tarafından alternatif tanımı yapılmış bir Türkçe kelime";
            newData[0].example = doc.example;
            newData[1].example = "";
            _setData(newData);

        });
        /*
        let body = input;
        body.data[0] = "";
        setIsLoading(true);
        //setWord("");
        //setSpelling("");
        const requestOptions = {
            method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)
        };
        fetch('https://notexist-ttt.hf.space/api/predict/', requestOptions)
            .then(response => response.json())
            .then(data => {
                let newData = JSON.parse(JSON.stringify(definitions));
                let outputs = data.data[0].split("\n\n")

                console.log(outputs[0]);
                console.log(outputs[1]);
                setWord(outputs[0]);
                setIsLoading(false);
                newData[0].definition = outputs[1];
                setSpelling(outputs[2].replaceAll(' ', ' — ').replaceAll('·', ' · '));
                if (outputs.length > 3) {
                    newData[0].example = outputs[3];
                }
                _setData(newData);
                return outputs[0];
            })
            .then(x => onClick?.call());
        //getMeaningOnTdk(x, onClick)*/
    }

    const getMeaning = (word, onClick = null) => {
        let body = input;
        body.data[0] = word;
        const requestOptions = {
            method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)
        };
        fetch('https://notexist-ttt.hf.space/api/predict/', requestOptions)
            .then(response => {
                if(response.ok)
                    return response.json();
                throw new Error(errorHint);
            })
            .catch(() => {
                setIsLoading(false);
                setHint(errorHint);
            })
            .then(data => {
                if (!data.hasOwnProperty("data"))
                    return null;
                let newData = JSON.parse(JSON.stringify(definitions));
                let outputs = data.data[0].split("\n\n")
                //setWord(outputs[0]);
                setIsLoading(false);
                if (outputs[0].startsWith('a.')) {
                    newData[0].definition = outputs[0].substring(2);
                    newData[0].type = "isim";
                    newData[1].type = "isim";
                } else if (outputs[0].startsWith("sf.")) {
                    newData[0].definition = outputs[0].substring(3);
                    newData[0].type = "sıfat";
                    newData[1].type = "sıfat";
                } else if (outputs[0].startsWith("zf.")) {
                    newData[0].definition = outputs[0].substring(3);
                    newData[0].type = "zarf";
                    newData[1].type = "zarf";
                } else if (outputs[0].startsWith("öz a.")) {
                    newData[0].definition = outputs[0].substring(5);
                    newData[0].type = "isim";
                    newData[1].type = "isim";
                } else {
                    newData[0].definition = outputs[0];
                }
                setSpelling(outputs[1].replaceAll(' ', ' — ').replaceAll('·', ' · '));
                if (outputs.length > 2) {
                    newData[0].example = outputs[2];
                }
                newData[1].example = "";
                newData[1].definition = wordFromUser;
                _setData(newData);
                return [newData, word];
            })
            .then(x => onClick?.call());
        //.then(x => getMeaningOnTdk(x, onClick));
    }

    useEffect(() => getRandomWord(/*(isMeaningful, sozlukName) => {
        if (isMeaningful) {
            let newData = JSON.parse(JSON.stringify(data));
            newData[1].definition = `TDK'da olan bir kelime: ${sozlukName}`;
            _setData(newData);
        }
    }*/), []);

    useEffect(() => {
            if (word.length < 16)
                setSize(isMobile ? 2.5 : 4);
            if (word.length > 16) {
                setSize(isMobile ? 1.8 : 3);
            }
            if (word.length > 22) {
                setSize(isMobile ? 1.1 : 2);
            }
            if (word.length > 27) {
                setSize(isMobile ? 0.6 : 1);
            }
        }, [word, isMobile])

    async function handleMenuItems(index)  {
        switch (index) {
            case 0:
                setIsTyping(true);
                setHint(initialHint);
                break;
            case 1:
                getRandomWord();
                break;
            case 2:
                await onShareButtonClick();
                break;
            case 3:
                await setNewWord(word);
                break;
            default:
                break;
        }
    }

    async function setNewWord(word) {
        log(word);
        if(word.length < 28){
            setIsLoading(true);
            await getMeaning(word, () => {
                /*if (isMeaningful) {
                    let newData = JSON.parse(JSON.stringify(data));
                    newData[1].definition = "TDK'da olan bir kelime:" + sozlukName;
                    _setData(newData);
                }*/
                setIsTyping(false);
                setIsLoading(false);
                setWord(word);
            });
        } else {
            setHint(errorHint);
        }

    }

    /*<!--<div className="title">{word}</div>-->*/
    const _setAboutContent = () => {
        setWord(aboutWord);
        setSpelling(aboutSpelling);
        _setData(about);
    };
    return (<div className="App">
        <canvas width={1080} height={1080} ref={canvasRef} id="canvas"/>
        <AppBar onClick={_setAboutContent}/>
        {!isTyping ? <div className="definitions-margin">
            <div ref={ref} className="definitions">
                <div className="title-container">
                    <div className="title-row"><TypeWriterEffect
                        textStyle={{fontSize: size + "em", width: size + "em", letterSpacing: "0.025em"}}
                        key={word + size}
                        startDelay={80}
                        cursorColor="black"
                        text={word}
                        typeSpeed={80}
                        hideCursorAfterText={true}
                    />
                        <div style={{height: (size + 1) + "em", width: 10, marginBottom: 8}}/>
                        <Loading isLoading={isLoading}/></div>
                    <div className="spelling-container">
                        {spelling}
                    </div>
                </div>
                {data.map((def, i) => <Definition key={i} def={def} index={i}/>)}
                <SignLanguage ref={signRef} word={word}/>
                {word !== '' ? <Menu ref={menuRef} onClick={(index) => handleMenuItems(index)}/> : <></>}
                <SharingFooter ref={sharingFooterRef}/>
            </div>
        </div> : <NewWord hint={hint} onSubmit={(word) => setNewWord(word)} isLoading={isLoading}/>}
        <div style={{height:150}}/>
        <Footer/>
    </div>);
}

function Footer() {
    return (<div className={"footer small-text"}>
        <hr/>
        <div className="footer-element"><a className="bmc-button" target="_blank"
                                                               style={{"alignItems": "center"}}
                                                               href="https://www.buymeacoffee.com/bukelimeyok"><span
            style={{"fontSize": "28px !important"}}>Destek olun</span><img
            src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
            alt="Buy me a coffee"/></a><a href="https://twitter.com/akoksal_">@akoksal_</a> / <a
            href="https://twitter.com/erkamsj">@erkamsj</a>
        </div>
        <div className="footer-element">{disclaimer}</div>
    </div>);
}

export default App;
