import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {getFirestore, collection, getDocs, getDoc, doc, deleteDoc, addDoc, setDoc} from 'firebase/firestore';
import {
    documentId,
    query,
    limit,
    orderBy,
    where,
} from "firebase/firestore";

const COLLECTION_SIZE = 2500;

const firebaseConfig = {
    apiKey: "AIzaSyDAZf_vThasE7FeuLKtstRkEeIU21Z2THQ",
    authDomain: "bukelimeyok.firebaseapp.com",
    projectId: "bukelimeyok",
    storageBucket: "bukelimeyok.appspot.com",
    messagingSenderId: "230497603670",
    appId: "1:230497603670:web:0495ad24ab75ab7c19ebf7",
    measurementId: "G-6Z4Q0MSJB6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const wordsCollection = collection(db, 'words');
const trendsCollection = collection(db, 'trends');

const _getFirebaseWord = async (callback = null) => {

    let key = doc(wordsCollection).id;

    let q = query(wordsCollection, where(documentId(), '>=', key), where("bukelimeyok", '==', true), limit(1))
    let snapshot = await getDocs(q);
    if (snapshot.size > 0) {
        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
        });
        return snapshot.docs[0].data();
    } else {
        let q = query(wordsCollection, where(documentId(), '<', key), where("bukelimeyok", '==', true), limit(1));
        snapshot = await getDocs(q);
        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
        });
        return snapshot.docs[0].data();
    }
}

const _log = async (word) => {
    logEvent(analytics, 'search_word', {
        word: word
    });
    await _addWordToTrends(word);
}

const _deleteWord = async (word) => {
    console.log(`WORD TO BE DELETED ${word}`);
    let q = query(wordsCollection, where('word', '==', word), limit(1))
    let snapshot = await getDocs(q);
    if (snapshot.size > 0) {
        console.log(`DOC TO BE DELETED ${snapshot.docs[0].data().word}`);
        await deleteDoc(snapshot.docs[0].ref);
    }

}

const _addWordToTrends = async (word) => {
    word = word.toLowerCase();
    let q = query(trendsCollection, where('word', '==', word), limit(1))
    let snapshot = await getDocs(q);
    if (snapshot.size > 0) {
        let doc = snapshot.docs[0];
        await setDoc(doc.ref, {
            'word' : word,
            'count': doc.data().count + 1,
            'share': doc.data()?.share ?? 0
        });
    } else {
        await setDoc(doc(db, "trends", word), {
            'word': word,
            'count': 1,
            'share': 0
        })
    }
}

const _increaseShare = async (word) => {
    word = word.toLowerCase();
    let q = query(trendsCollection, where('word', '==', word), limit(1))
    let snapshot = await getDocs(q);
    if (snapshot.size > 0) {
        let doc = snapshot.docs[0];
        await setDoc(doc.ref, {
            'word' : word,
            'count': doc.data().count,
            'share': doc.data().share + 1
        });
    } else {
        await setDoc(doc(db, "trends", word), {
            'word': word,
            'count': 0,
            'share': 1
        })
    }
}

export const App = app;
export const Analytics = analytics;
export const getFirebaseWord = _getFirebaseWord;
export const log = _log;
export const deleteWord = _deleteWord;
export const increaseShare = _increaseShare;